@import 'variables';

////
// Styles for forms, input fields and error messages related to user-input/-actions
////

label {
  display: block;
  font-weight: 600;
  margin-top: 1rem;
  margin-bottom: .5rem;
  max-width: 100%;
}

form input {
  background-color: white;
  border: 2px solid $border-col-primary-light;
  color: $font-color;
  display: block;
  font: 600 20px/25px 'Mulish';
  padding: 0 1rem;
  height: 4.125rem;
  width: 100%;
}

input[name="code"] {
  font: 600 40px/45px 'Mulish';
  max-width: 470px;
  text-align: center;
  letter-spacing: 1rem;
  // Ensure text is better vertically-centered on Safari
  line-height: 1.25em;

  //// Modify placeholder text-style,
  //// to give greater distinction between written text and placeholder
  &::placeholder {
    color: $bg-col-secondary-dark;
    font-weight: 300;
  }

  // iPhone 5(s) and SE size.
  // Where the placeholder text overflows a tiny bit
  @media screen and (max-width: 320px) {
    font-size: 35px;
  }
}

input[type=text]:focus,
input[type=text]:active,
input[type=text]:hover,
input[type=password]:focus,
input[type=password]:active,
input[type=password]:hover {
  outline: $border-col-primary-light solid 2px;
  padding: 0 1rem;
}

form.error input[type=text],
form.error input[type=password] {
  border: 3px solid $border-col-tertiary;
}

form.error input[type=text]:focus,
form.error input[type=text]:active,
form.error input[type=text]:hover,
form.error input[type=password]:focus,
form.error input[type=password]:active,
form.error input[type=password]:hover {
  outline-color: $border-col-tertiary;
}

// Custom checkboxes

%css-checkbox-base {
  position: absolute;
  z-index: -1000;
  left: -1000px;
  overflow: hidden;
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
}

%css-label-base {
  width: auto !important;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
}

input[type=checkbox].css-checkbox {
  @extend %css-checkbox-base;

  + label.css-label {
    @extend .icon-checkbox-unchecked;
    @extend %css-label-base;
    padding-left: 51px;
    height: 35px;
    display: inline-block;
    line-height: 35px;
    font-size: 16px;
    vertical-align: middle;
    cursor: pointer;
  }

  &:checked + label.css-label {
    @extend .icon-checkbox-checked;
  }
}

// Styles for checkboxes - focus and hover

input[type=checkbox].css-checkbox:focus + label.css-label,
input[type=checkbox].css-checkbox + label.css-label:hover {
  text-decoration: underline;
}

input[type=checkbox].css-checkbox:checked:focus + label.css-label,
input[type=checkbox].css-checkbox:checked + label.css-label:hover {
  @extend .icon-checkbox-checked-filled;
}

input[type=checkbox].css-checkbox:focus + label.css-label,
input[type=checkbox].css-checkbox + label.css-label:hover {
  @extend .icon-checkbox-unchecked-filled;
}

// Styles for small checkboxes
input[type=checkbox].css-checkbox-small {
  @extend %css-checkbox-base;

  + label.css-label-small {
    @extend .icon-checkbox-small-unchecked;
    @extend %css-label-base;
    padding-left: 2rem;
    height: 36px;
    display: inline-block;
    line-height: 36px;
    font-size: 1rem;
    vertical-align: middle;
    cursor: pointer;
  }

  &:checked + label.css-label-small {
    @extend .icon-checkbox-small-checked;
  }
}

// Styles for small checkboxes - focus and hover
// NB: outline here is a bit weird, but it is to ensure large enough clicking
// area on phones (UU-reasons)
input[type=checkbox].css-checkbox-small:focus + label.css-label-small,
input[type=checkbox].css-checkbox-small + label.css-label-small:hover {
  outline: 1px dashed red;
}

// styles for checkboxes in error forms
form.error input[type=checkbox].css-checkbox + label.css-label {
  @extend .icon-checkbox-error-unchecked;
}

form.error input[type=checkbox].css-checkbox:focus + label.css-label,
form.error input[type=checkbox].css-checkbox + label.css-label:hover {
  @extend .icon-checkbox-error-unchecked-filled;
}

form.error input[type=checkbox].css-checkbox:checked + label.css-label {
  @extend .icon-checkbox-error-checked;
}

form.error input[type=checkbox].css-checkbox:checked + label.css-label:hover,
form.error input[type=checkbox].css-checkbox:checked:focus + label.css-label {
  @extend .icon-checkbox-error-checked-filled;
}

// Custom style for accessibility reasons
// TODO(fredrfli) Is this actually used anymore?
.accessibility-error-desc {
  display: none;
}
