@import 'variables';

////
// Selectize styles
////

// General selectize styles

.selectize-control {
  .item, .option {
    .flag-wrapper {
      display: flex;
      align-items: center;

      .flag {
        margin-right: .4rem;
      }
    }
  }

  .selectize-input input {
    margin-right: 0px !important;
    width: 0 !important;
  }
}

.selectize-input, .selectize-input input, .selectize-dropdown {
  font-family: 'Mulish', sans-serif;
  font-size: 1rem;
  line-height: 1.25rem;
}

.selectize-input {
  border: 2px solid $border-col-primary-light;
  border-radius: 0;
  box-shadow: none;
  padding-right: 2.2em;
}

.selectize-input.dropdown-active {
  border-radius: 0;
  box-shadow: none;
}

.selectize-dropdown {
  border: 0;
  min-width: 10em !important;
  left: auto !important;
  right: 0 !important;
}

.selectize-dropdown:hover,
.selectize-dropdown:focus {
  cursor: pointer;
}

.selectize-dropdown .active {
  background-color: $bg-col-default;
  color: $font-color;
}

.selectize-dropdown-content {
  max-height: 19.1rem;
}

.selectize-dropdown-content .option, .selectize-dropdown-content .optgroup-header {
  border: 2px solid $border-col-primary-light;
  margin-bottom: -2px;
  padding: .5em;
}

.selectize-dropdown-content .optgroup-header {
  padding-left: 3.6875rem;
  font-size: smaller;
  //font-weight: bold;
  border-top: none;
  background-color: $bg-col-header;
}

.selectize-dropdown-content .option:last-child {
  margin-bottom: 0;
}

.selectize-control.single .selectize-input:after {
  // arrow-down
  @extend .icon-arrow-down-small;
  border: 0;
  content: '';
  position: absolute;
  right: .625rem;
  margin-top: -4px;
}

.selectize-control.single .selectize-input.dropdown-active:after {
  // arrow-down (mirrored)
  @extend .icon-arrow-up-small;
}

.media-object.selectize-control.single .selectize-input:after {
  // arrow-down
  @extend .icon-arrow-down;
  border: 0;
  content: '';
  position: absolute;
  right: .625rem;
  margin-top: -6px;
}

.media-object.selectize-control.single .selectize-input.dropdown-active:after {
  @extend .icon-arrow-up;
}

.selectize-dropdown-content .option.active {
  border-width: 2px;
  outline: 2px solid $border-col-primary-light;
  outline-offset: -4px;
}

// For selectize with media objects as options

.media-object .selectize-input {
  height: 4.125rem;
  padding: 0;
}

.media-object .selectize-input input {
  height: 100%;
  padding-left: 1rem !important;
  width: 100% !important;
}

.media-object .selectize-input .item .media-left {
  height: 42px;
}

.media-object .selectize-input .item .media-left img {
  width: 42px;
}

.media-object .selectize-dropdown-content .option {
  padding: 0;
}

.media-object .media {
  margin: 0;
}

.media-object .selectize-dropdown-content .option .media-left {
  height: 32px;
  width: 32px;
}

.media-object .selectize-dropdown-content .option .media-left img {
  max-width: 32px;
  max-height: 32px;
}
