
.twofactorlogo {
  $twofactor-size: 75px;

  border-radius: $twofactor-size;
  float: left;
  height: $twofactor-size;
  margin: 0 1em 0 0;
  padding: 0;
  width: $twofactor-size;

  img {
    box-sizing: border-box;
    width: 100%;
  }
}
