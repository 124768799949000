@import '../variables';

.selectorg-affiliation-header {
  color: $font-color;
  font-size: 1.0rem;
  font-weight: bold;
  margin-bottom: 1px;
  display: flex ;
  > div {
    font-weight: 400;
  }
}

.org-header {
  border-bottom: 2px solid $bg-col-body;
  margin: 0 0 .6875rem 0;
}

#orglist_wrapper {
  max-height: 165px;
  overflow: hidden;
  overflow-y: scroll;
  box-shadow: 0 2px 4px 0 rgba(102, 102, 102, 0.2), 0 4px 5px 0 rgba(102, 102, 102, 0.01)
}

#orglist {
  border: 0 transparent $bg-col-btn-primary-dark;
  border-width: 0 0 2px 2px;
  margin-top: 0px;
  display: none;
}

.last_recent{
  border-bottom: 2px solid $bg-col-body !important;
}

.orglist_item{
  cursor: pointer;
  user-select: none;
  border: 0 transparent $bg-col-btn-primary-dark;
  border-width: 2px 2px 0 0;
  padding: 10px;
  font-size: 18px;
  &:focus {
      background-color:   #f4f4f4;
  }
  &:hover {
    background-color: #f4f4f4; 
}
  &.chosen {
      background-color: $bg-col-body;
  }
}

.orglist_item_wrapper {
  display: flex;
  align-items: center;
}

.recents_label{
  font-weight:bold;
  text-align: left;
  margin-left: 10px;
  font-size: 0.8rem;
}

.orglist_logo {
  max-height: 34px;
  max-width: 34px;
}

.orglist_logo_wrapper {
  height: 34px;
  width: 34px;
  justify-items: center;
  align-items: center;
  display: grid;
  margin-right: 15px;
}

#hit-amount {
  margin-top: 8px;
  width: 1px;
  height: 1px;
}

.hit-label {
  text-align: right;
  float: right;
  width: 1px;
  height: 1px;
  position: absolute;
  margin-bottom: -1px;
  margin-right: -1px;
  clip: rect(0px 0px 0px 0px);
}

#hits-found {
  text-align: right;
  color: grey;
  font-size: small;
  float: right;
  width: 100%;
}

.more-info-button{
  border-radius: 50%;
  background-color: #283678;
  color: white;
  font-weight: 800;
  min-width: 20px;
  padding-right: 1px;
}