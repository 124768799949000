////
// Small utility styles that is usable globally, such as .slim, .left, .clear, etc.
// Should be identified by class-names
////

.slim {
  margin: 0px;
}

.left {
  float: left;
}

.right {
  float: right;
}

.clear {
  clear: both;
}

.hidden {
  display: none !important;
}

.not-visible {
  visibility: hidden;
}

.has-bold-font {
  font-weight: bold;
}

.breathe-top {
  margin-top: 1rem;
}

.clear-top-padding {
  padding-top: 0;
}

// Style for centering a right arrow - remember position relative on parent
.right-arrow {
  position: absolute;
  right: 1em;
  top: 50%;
  transform: translateY(-50%);
  width: 14px;
}

.show-for-sr {
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
  height: 1px;
  width: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
}

.desaturate {
  filter: grayscale(1);
}

.toggle-button {
  background-color: transparent;
  margin-bottom: 0;
  padding: 0;
  height: auto;
  text-align: left;

  &:focus,
  &:hover {
    background-color: transparent;
    outline: 0;
  }
}


.attribute-category {
  font-size: 16px;
  font-weight: bold;
}

.attribute-name {
  font-size: 12px;
}

.attribute-description {
  font-size: 12px;
  display: block;
}
