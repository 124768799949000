// Utility function to generate the relative url to an icon.
@function iconUrl($name) {
  @return url("../img/icons/#{$name}.svg");
}

// Common style for all icon types
%icon-common {
  background-repeat: no-repeat;
  display: inline-block;
}

// General

.icon-minus {
  @extend %icon-common;
  background: iconUrl("minus");
  width: 20px;
  height: 20px;
}

.icon-plus {
  @extend %icon-common;
  background: iconUrl("plus");
  width: 20px;
  height: 20px;
}

.icon-info {
  @extend %icon-common;
  background: iconUrl("info");
  background-color: $bg-col-default;
  width: 22px;
  height: 22px;
}

.icon-info-feide {
  @extend %icon-common;
  background: iconUrl("info-icon");
  width: 24px;
  height: 24px;
  vertical-align: -0.125em;
}

.icon-small-cross {
  @extend %icon-common;
  background: iconUrl("small-cross");
  width: 20px;
  height: 20px;
}

.icon-cross {
  @extend %icon-common;
  background: iconUrl("cross");
  width: 24.11px;
  height: 24.03px;
}

.icon-cross-hover {
  @extend %icon-common;
  background: iconUrl("cross-hover");
  width: 24.11px;
  height: 24.03px;
}

.icon-globe {
  @extend %icon-common;
  background: iconUrl("globe");
  width: 19px;
  height: 19px;
}

// Flags

%icon-flag-base {
  @extend %icon-common;
  width: 19px;
  height: 14.25px;
}

@each $lang in ['en', 'nn', 'no', 'se'] {
  .icon-flag-#{$lang} {
    @extend %icon-flag-base;
    background: iconUrl("flag-#{$lang}");
  }
}

// Checkbox

%icon-checkbox-base {
  @extend %icon-common;
  width: 35px;
  height: 35px;
}

// Does not include small, which has different width and height
$regular-checkboxes: 'checked', 'checked-filled', 'unchecked', 'unchecked-filled', 'error-checked', 'error-checked-filled', 'error-unchecked', 'error-unchecked-filled';

@each $checkbox in $regular-checkboxes {
  .icon-checkbox-#{$checkbox} {
    @extend %icon-checkbox-base;
    background: iconUrl("checkbox-#{$checkbox}");
  }
}

.icon-checkbox-small-checked {
  @extend %icon-common;
  background: iconUrl("checkbox-small-checked");
  width: 22px;
  height: 36px;
}

.icon-checkbox-small-unchecked {
  @extend %icon-common;
  background: iconUrl("checkbox-small-unchecked");
  width: 22px;
  height: 36px;
}

// Avatar

.icon-avatar {
  @extend %icon-common;
  background: iconUrl("avatar");
  width: 26px;
  height: 27px;
}

// Arrow

.icon-arrow-down {
  @extend %icon-common;
  background: iconUrl("arrow-down");
  width: 24px;
  height: 13.719999999999999px;
}

.icon-arrow-right {
  @extend %icon-common;
  background: iconUrl("arrow-right");
  width: 13.72px;
  height: 24px;
}

.icon-arrow-up {
  @extend %icon-common;
  background: iconUrl("arrow-up");
  width: 24px;
  height: 13.719999999999999px;
}

.icon-arrow-up-small {
  @extend %icon-common;
  background: iconUrl("arrow-up-small");
  width: 18px;
  height: 10.29px;
}

.icon-arrow-down-small {
  @extend %icon-common;
  background: iconUrl("arrow-down-small");
  width: 18px;
  height: 10.29px;
}

// Status (Loading, failure, etc)

.icon-loading {
  @extend %icon-common;
  background: iconUrl("loading");
  width: 50px;
  height: 50px;
}

.icon-loading2 {
  @extend %icon-common;
  background: iconUrl("loading2");
  width: 50px;
  height: 50px;
}

.icon-failure {
  @extend %icon-common;
  background: iconUrl("failure");
  width: 22px;
  height: 22px;
}

// Logout

.icon-logout {
  @extend %icon-common;
  background: iconUrl("logout");
  transform: scale(1.5) rotate(135deg);
  width: 26px;
  height: 19.34px;
}

// Feide logo

.icon-feide {
  @extend %icon-common;
  background: iconUrl("feide");
  width: 50px;
  height: 50.03px;
}

// Third party service/logo

.icon-idporten {
  @extend %icon-common;
  background: iconUrl("idporten");
  width: 50px;
  height: 50px;
}

.icon-linkedin {
  @extend %icon-common;
  background: iconUrl("linkedin");
  width: 50px;
  height: 50px;
}

.icon-twitter {
  @extend %icon-common;
  background: iconUrl("twitter");
  width: 50px;
  height: 50px;
}

.icon-edugain {
  @extend %icon-common;
  background: iconUrl("edugain");
  width: 41.4px;
  height: 50px;
}

.icon-facebook {
  @extend %icon-common;
  background: iconUrl("facebook");
  width: 50px;
  height: 50px;
}

// 2FA icons

%two-factor-icon-common {
  @extend %icon-common;
  background-position: center;
  background-size: contain;
  height: 100%;
  width: 100%;
}

.two-factor-icon-id-porten {
  @extend %two-factor-icon-common;
  background-image: iconUrl("idporten");
}
  
.two-factor-icon-qrcode {
  @extend %two-factor-icon-common;
  background-image: iconUrl("qrcode");
}
  
.two-factor-icon-azuread {
  @extend %two-factor-icon-common;
  background-image: url(../../public/img/ms-symbollockup_mssymbol_19.svg);
}
  
.two-factor-icon-mobile-phone {
  @extend %two-factor-icon-common;
  background-image: iconUrl('mobile-phone');
}

.two-factor-icon-fido-passkey-logo {
  @extend %two-factor-icon-common;
  background-image: url('../img/fido-passkey-logo.svg');
}
