
.passwordfield {
  // Has to be set for items with position: absolute inside this box,
  // to correctly position themselves
  position: relative;
}

$password-padding-right: 5rem;

// Style only specific to a button on the login form.
// Ensures the button fit visually inside the password field
.button-password-showhide {
  // This is the height that the password field has (see ../forms.scss),
  $form-input-height: 4.125rem;
  // Determines the distance from the input field's borders
  $button-margin-percentage: 0.15;
  $button-height-percentage: 1.0 - ($button-margin-percentage * 2);

  height: $form-input-height * $button-height-percentage;

  $button-margin: $form-input-height * $button-margin-percentage;
  margin: $button-margin $button-margin 0 0;

  position: absolute;
  right: 0;

  top: 0;
  width: auto;
  // Avoid that the button overflows into the contents of the password field
  max-width: $password-padding-right - $button-margin;
}

#password {
  // Add additional padding to the password fields,
  // so we avoid that the Show/Hide-password button overlaps with the contents
  padding-right: $password-padding-right;

  // Hide MS Edge's Password reveal button
  // https://docs.microsoft.com/en-us/microsoft-edge/web-platform/password-reveal
  &::-ms-reveal {
    display: none;
  }
}
