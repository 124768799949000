// Collection of variables used in multiple files.
// Colors should always go here for better consistency in colors and gradients, across pages.

$bg-col-body: #C5D4E7;
$bg-col-header: #DBE5F1;
$bg-col-footer: $bg-col-header;
$bg-col-default: #FFFFFF;
$bg-col-primary-light: #E2E9F3;
$bg-col-primary-dark: #92B9ED;

$bg-col-panel: $bg-col-header;
$bg-col-btn-primary: #92B9ED;
$bg-col-btn-primary-dark: #283678;
$bg-col-btn-secondary: #D3D3D3;
$bg-col-btn-secondary-dark: #6C6C6C;

$bg-col-secondary-light: #EEEEEE;
$bg-col-secondary-dark: #D3D3D3;
$bg-col-tertiary: #FFD1D1;

$border-col-primary-dark: #283678;
$border-col-primary-light: #606CA5;
$border-col-secondary: #A3A4A8;
$border-col-tertiary: #F10606;

$font-color: #020202;
$font-color-light: white;
$font-color-footer: #2D384C;
$h1-color: #333333;
$h2-color: #000000;
$link-color: #040404;
$link-focus-color: #92B9ED;
$link-focus-color-tertiary: #DB9999;
$color-list-group-item-button: #2D384C;  // What is this?
$color-avatar-border: #45a467;

$button-showhide-password-border: #C4DBF3;

$feide-blue: #1f4698;
$light-blue: #e3f2ff;
