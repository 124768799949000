@import '../variables';

.panel {
  background-color: $bg-col-default;
  padding: 1rem 1rem;
  margin-bottom: 1rem;
  margin-top: .5rem;

  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;

  i.icon {
    float: right;
  }

  &.standout {
    background-color: $bg-col-primary-light;
  }

  &.error {
    background-color: $bg-col-tertiary;

    i.icon {
      @extend .icon-failure;
    }

    a:focus {
      background-color: $link-focus-color-tertiary;
    }
  }

  &.dismissible {
    i.icon {
      @extend .icon-small-cross;
      cursor: pointer;
    }
  }

  &.expandable {
    padding: 0.55rem 1rem;
    border-top: 2px solid $bg-col-body;
    border-bottom: 2px solid $bg-col-body;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;

    .panel-heading {
      cursor: pointer;
      position: relative;

      .icon {
        @extend .icon-plus;
        position: absolute;
        right: 0px;
      }
    }

    &.expanded .panel-heading .icon {
      @extend .icon-minus;
    }

    .panel-content {
      margin-top: 1rem;

      .logo {
        float: right;

        img {
          max-width: 50px;
          max-height: 50px;
        }
      }
    }

    &.standout {
      border: none;
      margin-top: 0.25rem;
      margin-bottom: 0.25rem;
    }
  }

  // Ensure that floating elements in panels (images) do not overflow the panel.
  // See: https://stackoverflow.com/a/1633170
  &::after {
    content: "";
    display: block;
    clear: both;
  }
}

.panel-list {
  .panel {
    padding: .55rem 1rem;
    margin: .25rem 0;
  }

  .panel-heading {
    h3 {
      font-weight: normal;
      margin: 0;
    }
  }
}

.panel.expandable {
  &:hover, &.focused {
    border: 2px solid $border-col-primary-light;

    button {
      color: $font-color;
    }
  }
}

@supports (outline-offset: -2px) {
  .panel.expandable {
    &:hover, &.focused {
      border-left: 0;
      border-right: 0;
      outline: 2px solid $border-col-primary-light;
      outline-offset: -2px;
    }
  }
  .panel.expandable.standout {
    &:hover, &.focused {
      border: 0;
    }
  }
}
