@import 'variables';

.item {
  .media {
    display: flex;
    padding: .38rem;
    width: 100%;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    .media-left {
      display: block;
      white-space: nowrap;
      text-align: center;

      .helper {
        display: inline-block;
        height: 100%;
        vertical-align: middle;
      }

      img {
        max-width: 42px;
        max-height: 42px;
        width: auto !important;
        height: auto;
        vertical-align: middle;
      }
    }

    .media-body {
      display: block;
      text-align: center;
    }
  }
}

// Styles used different places to wrap .media and other relevant content.
// We are using two class-names in lack of a better name
.org-header,
.sp-header {
  border-bottom: 2px solid $bg-col-body;
  margin: 0 0 .6875rem 0;
}

.media {
  background-color: $bg-col-default;
  display: table;
  padding: .6875rem;
  //margin-bottom: 1rem;
  width: 100%;

  &.error {
    background-color: $bg-col-tertiary;

    i.icon {
      @extend .icon-failure;
    }

    a:focus {
      background-color: $link-focus-color-tertiary;
    }
  }
}

.media-left {
  display: table-cell;
  min-height: 50px;
  width: 50px;
  height: 50px;
  vertical-align: middle;
  text-align: center;
}

#header .media-left {
  vertical-align: top;
}

.media > .media-left > img {
  max-width: 50px;
}

.media > .media-body {
  display: table-cell;
  padding-left: 1em;
  padding-right: 1em;
  vertical-align: top;
}

.media > .media-body.align-middle {
  vertical-align: middle;
}

.media > .media-body h1 {
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.25rem;
  margin-bottom: .3125rem;
}

.media .media-right {
  min-width: 14px;
  width: 14px;
  height: 24px;
  display: table-cell;
  vertical-align: middle;
}

// Loading indicator for list-items
// This will only work properly for specific elements, but can be used in any

.media.loading .icon {
  @extend .icon-loading;
}

.media.loading .media-right {
  display: table-cell;
}
