.font-style-h3 {
    font-size: 16px;
    font-weight: 600;
    font-style: normal;
    color: black;
    margin: 0.2rem 0;
    line-height: 1.5;
}

.font-style-h3-small {
    @extend .font-style-h3;
    font-size: 16px;
    font-weight: 400;
}

.banner {
    padding: 16px;
    display: flex;
    align-items: flex-start; // Align items at the start of the container
}

.banner-title {
    @extend .font-style-h3;
    line-height: 1em;
    padding-bottom: 12px;
    font-weight: bold;
}

.banner-small-title {
    @extend .font-style-h3-small;
    line-height: 1.5em;
    padding-bottom: 12px;
}

.banner-icon-container {
    display: flex;
    align-items: center;
    margin-right: 12px; // Add some space between the icon and the text
}

.banner-content-container {
    display: flex;
    flex-direction: column;
}

.banner-close-button {
    border: none;
    background-color: transparent;
    border-radius: 22px;
    font-size: 24px;
    padding: 0;
    position: relative;
    top: -10px;
    right: -10px;
    width: 44px;
    height: 44px;

    &:active {
        background-color: rgba(0, 0, 0, 0.1);
    }

    &:focus-visible {
        outline: black auto 1px;
    }
}

.banner-info {
    border-left: solid 2px $feide-blue;
    background-color: $light-blue;
}

.banner-info .banner-icon-container {
    color: $feide-blue;
}

// Remove margins for often used alert content as it ruins the layout
.banner p,
.banner h2,
.banner h3,
.banner h4 {
    margin: 0;
}