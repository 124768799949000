@import 'variables';

// Styles for page setup or that affects large parts of the page.
// Normally, styles that do not target a specific class-name or id-selector.

* {
  box-sizing: border-box;
}

:focus {
  outline: none;
}

::-moz-focus-inner {
  border: 0;
}

[aria-hidden="true"] {
  display: none !important;
}

pre,
code,
kbd,
samp,
tt {
  font-family: monospace, monospace;
  font-size: .9rem;
  background-color: rgba(0, 0, 0, .1);
  padding: 0 .2rem;
}

pre {
  padding: .5rem 1rem;
}

em {
  font-style: italic;
}

html {
  font-size: 100%;

  @media (max-width: 20em) {
    font-size: 90%;
  }
}

body {
  background-color: $bg-col-body;
  color: $font-color;
  font-family: 'Mulish', sans-serif;
  line-height: 1.25rem;
}

article {
  padding: 1em;
}

footer {
  background-color: $bg-col-footer;
  padding: 2em;
  text-align: center;
  color: $font-color-footer;
}

footer p {
  margin-bottom: 0px;
  margin-top: 0px;
}

p {
  margin-bottom: 1rem;
  margin-top: .5em;
}

strong {
  font-weight: bold;
}

// Headings

h1 {
  color: $h1-color;
  font-size: 1.875rem;
  font-weight: 900;
  line-height: 2.875rem;
  margin-bottom: .5rem;
}

// Placed after h1 to ensure the style isn't overridden
@media screen and (max-height: 730px) {
  body#feide\:login h1 {
    display: none;
  }
}

h2 {
  color: $h2-color;
  font-size: 1.25rem;
  font-weight: bold;
  line-height: 1.5625rem;
  margin-bottom: .5rem;
}

h3 {
  font-weight: bold;
  margin-bottom: .2rem;
}

// Links

small {
  font-size: 0.875rem;
  line-height: 1.125rem;
}

a {
  color: $link-color;

  &:focus, &:hover {
    background-color: $link-focus-color;
    outline: 0;
  }

  &.backlink {
    display: none;
  }
}

// Styles the div wrapping

#wrapper {
  background-color: $bg-col-default;
  max-width: 502px;
  margin: 1em auto;

  @media (max-width: 26.5625em) {
    margin: 0 auto;
  }
}

#main-head {
  background-color: $bg-col-header;
  padding: .3em;
}

#main .main .dialog.notification {
  background-color: #faebb5;
  padding: 14px;
  border: 2px solid #aaa;
}
